// Base Settings - Vertical Rythm Mixin
html {
  font-size: #{$base-font-size + '%'};

  @include breakpoint(small down) {
    font-size: #{$base-font-size-small + '%'};
  }
}

// * {
//   @include line-height(1);
//   @include margin-bottom(1);
//   @include margin-top(0);
// }

// Typography
body {
  font-family: 'Apercu Pro';
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Platform LC Web';
  font-weight: normal;

  strong {
    em {
      font-style: inherit;
    }
  }
}

h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.025em;
}

h1 {
  font-size: 295%;

  @include breakpoint(large down) {
    font-size: 250%;
    @include line-height(1.625);
    @include margin-bottom(1.5);
  }

  @include breakpoint(small down) {
    font-size: 160%;
    @include line-height(1.125);
    @include margin-top(2);
    @include margin-bottom(1);
  }
}

h2 {
  font-size: 180%;

  @include breakpoint(small down) {
    font-size: 140%;
    @include line-height(1.125);
    @include margin-top(1.5);
    @include margin-bottom(0.75);
  }

  aside & {
    @include margin-top(1.75);
    @include margin-bottom(0.75);

    @include breakpoint(small down) {
      @include margin-top(1.5);
    }
  }
}

h3 {
  font-size: 145.45%;

  @include breakpoint(large down) {
    font-size: 120%;
    @include line-height(1);
    @include margin-top(1);
    @include margin-bottom(0.75);
  }

  @include breakpoint(medium down) {
    font-size: 110%;
    @include line-height(0.75);
  }
}

h4 {
  font-size: 120%;
  @include breakpoint(small down) {
    font-size: 116%;
    @include line-height(1);
    @include margin-bottom(0.75);
  }
}

h5 {
  font-size: 114%;
  @include breakpoint(small down) {
    font-size: 114%;
    @include line-height(1);
    @include margin-bottom(0.75);
  }
}

p {
  letter-spacing: -0.01em;

  &.preamble {
    font-family: 'Berlingske Serif';
    font-size: 140%;
    @include line-height(1.25);
    @include margin-bottom(1.375);
    letter-spacing: initial;
    -webkit-font-smoothing: initial;

    @include breakpoint(small down) {
      font-size: 130%;
      @include line-height(1.125);
      @include margin-bottom(1);
    }
  }

  small,
  &.detail {
    font-size: 80%;
    @include line-height(0.75);
  }
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-table;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 24.99%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 74.99%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}
@media only screen and (max-width: 980px) {
  .col-3,
  .col-4 {
    width: 50%;
  }
  .col-2 {
    width: 33%;
  }
  .col-1 {
    width: 25%;
  }

  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .only-desktop {
    display: none;
  }
}
.center {
  text-align: center;
}
.vtop {
  vertical-align: top;
}
