section {
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  background-size: cover;
  background-position: center center;

  @include breakpoint(xxlarge down) {
    background-size: auto 200%;
  }
}

.full-size-section {
  /*display: inline-block;*/
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  .coverElement {
    position: absolute;
    background-color: #000;
    height: 100%;
    left: 0px;
    opacity: 0.15;
    top: 0px;
    width: 100%;
  }
}

@media only screen and (max-width: #{map-get($breakpoints, 'medium')}) {
  .full-size-section {
    margin-bottom: 20px;
  }
}

.content-section {
  width: 98%;
  max-width: calc(#{map-get($breakpoints, 'xlarge')} + #{$global-gutter});
  margin: 0 auto;
  white-space: pre-line;
  padding: 5px 40px;
  @extend .gutter;

  h1,
  h2,
  h3,
  h4 {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
  }

  .mind-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    justify-content: space-between;
    .mind-card {
      text-align: center;
      padding: 10px;
      margin: 10px;
      margin-bottom: 20px;
    }

    .mind-card img {
      width: 80%;
      margin-bottom: 15px;
      transition: transform 0.2s;
      filter: grayscale(100%);
    }
  }

  .banner {
    height: 45 0px;
    .bannerText {
      position: relative;
    }
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // grid-template-columns: repeat(3, 1fr);
    // column-gap: 2em;
    // row-gap: 2em;
    margin-top: 40px;

    // justify-content: space-between;
    // align-items: stretch;
  }

  .job-card-first {
    text-align: center;
    align-items: center;
    background-color: #f5f5f5;

    grid-column-start: 1;
    grid-column-end: 3;
  }
  .job-card-first p {
    color: black;
  }
  .job-card-first a {
    text-decoration: none;
    color: #f1635f;
  }
  .job-card-first .card-text {
    width: 50%;
    margin: auto;
  }

  .job-card-first a:hover {
    color: #71acda;
  }

  .job-card {
    width: 300px;
    height: 300px;
    text-align: left;
    background-color: #f5f5f5;
    // width: 32%;
    padding: 35px;
    margin: 40px;
  }

  .job-card p {
    color: black;
  }
  .job-card a {
    text-decoration: none;
    color: #f1635f;
  }
  .job-card a:hover {
    color: #71acda;
  }

  @media only screen and (max-width: 700px) {
    .job-card-first {
      text-align: left;
      padding: 20px;
    }
    .job-card-first .card-text {
      width: 100%;
    }
    // .job-card {
    //   width: 250px;
    //   height: 250px;
    //   text-align: left;
    //   padding: 35px;
    // }
  }

  .crowdCard {
    width: 300px;
    text-align: center;
    overflow: hidden;
    margin: 5% 0;
  }
  .crowdCard a {
    color: #f1635f;
  }
  .crowdCard a:hover {
    color: #71acda;
  }

  @media only screen and (max-width: #{map-get($breakpoints, 'medium')}) {
    .mind-grid {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .banner {
      height: 320px;
      .bannerText {
        position: relative;
        top: 0%;
      }
    }

    .card-container {
      display: block;
    }
    .job-card {
      width: 250px;
      height: 250px;
      padding: 18px;
      margin: 0 0 35px 0;
    }
  }

  @media only screen and (max-width: #{map-get($breakpoints, 'large')}) {
    .text-image-right img {
      display: none;
    }
  }

  .text-image {
    display: flex;
    padding-top: 5px;

    &-top {
      @extend .text-image;
      flex-direction: column;
      align-items: center;

      div {
        padding-top: 10px;
        flex: 0 1 50%;
        padding-bottom: 40px;
        width: 50%;
      }
      img {
        margin-bottom: 10px;
        width: 100%;
        max-height: 400px;
        flex: 0 1 50%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    &-bottom {
      @extend .text-image;
      flex-direction: column;

      div {
        flex: 0 1 50%;
      }
      img {
        height: 400px;
        max-width: 400px;
        flex: 0 1 50%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    &-right {
      @extend .text-image;
      flex-direction: row;

      div {
        flex: 0 1 100%;
        padding-right: 40px;
      }
      img {
        margin-left: auto;
        height: 400px;
        max-width: 500px;
        flex: 0 1 50%;
        object-fit: cover;
        object-position: 50% 50%;
        margin-top: 30px;
      }
    }

    &-text-only {
      @extend .text-image;
      flex-direction: row;
      justify-items: center;
      div {
        flex: 0 1 66%;
      }
      img {
        display: none;
      }
    }
  }
}
.text-image a:hover {
  color: #71acda;
}

@media only screen and (max-width: '900px') {
  .text-image.text-image-top div {
    width: 100%;
  }
  .text-image.text-image-text-only {
    div {
      flex: 0 1 100%;
    }
  }
}

section.hero {
  h1 {
    @include breakpoint(large up) {
      margin-top: auto;
      @include margin-bottom(2);
    }
  }

  div[class$='-inner'] {
    @include padding-top(1.5);
    @include padding-bottom(2.5);
    min-height: 28em;

    @include breakpoint(xlarge up) {
      min-height: 33em;
    }

    @include breakpoint(medium down) {
      @include padding-top(1);
      @include padding-bottom(1.5);
      min-height: initial;
    }

    img.nvrmind-logo {
      margin-bottom: 0;
      width: 154px;
      height: 60px;

      @include breakpoint(medium down) {
        width: 110px;
        height: 43px;
      }
    }
  }

  .cta {
    opacity: 0;
    animation: fadeInAnimation 0.5s cubic-bezier(0, 1, 0.8, 1) 1;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    align-self: flex-start;
    text-indent: -0.4em;

    @keyframes fadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}

section.about {
  .cta {
    @include margin-top(0.5);
    align-self: flex-start;
    text-indent: -0.4em;
  }
}

section.keywords {
  em {
    background-color: rgba(darken($purple, 10%), 0.8);
    color: $white;
  }
}

section.slogan {
  position: relative;
  color: $black;

  img {
    margin-bottom: 0;
  }

  p {
    color: $black;
    @extend .gutter;
    max-width: calc(#{map-get($breakpoints, 'large')});
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(small down) {
      font-size: 100%;
      @include line-height(0.875);
    }
  }

  @include padding-top(2);
  @include padding-bottom(1);

  @include breakpoint(medium down) {
    @include padding-top(1);
    @include padding-bottom(0.5);
  }

  .slogan-inner {
    overflow: hidden;
    position: relative;
    color: $eggshell;
    @include padding-top(1);
    @include padding-bottom(1);
  }

  .section-content {
    padding-left: 0;
    padding-right: 0;
    max-width: calc(#{map-get($breakpoints, 'large')} - #{$global-gutter});

    @include breakpoint(large only) {
      width: calc(#{map-get($breakpoints, 'large')} - #{$global-gutter});
    }

    @include breakpoint(large down) {
      max-width: calc(100% - #{$global-gutter} - #{$global-gutter});
    }

    @include breakpoint(small down) {
      max-width: calc(100% - #{$global-gutter-small} - #{$global-gutter-small});
    }
  }

  .rolling-text-cover {
    position: absolute;
    top: 50%;
    color: inherit;

    font-size: 12em;
    font-weight: bold;
  }

  .rolling-text {
    position: absolute;
    display: block;
    z-index: 10;
    top: 50%;
    left: 50%;
    display: inline-block;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
    -webkit-animation: slideLeft 70s linear infinite;
    -moz-animation: slideLeft 70s linear infinite;
    -ms-animation: slideLeft 70s linear infinite;
    -o-animation: slideLeft 70s linear infinite;
    animation: slideLeft 70s linear infinite;

    margin-bottom: 0;
    line-height: initial;

    @include breakpoint(large down) {
      font-size: 20vw;
    }

    span {
      font-size: 0.75em;
      position: relative;
      bottom: 0.1em;
    }
  }

  @keyframes slideLeft {
    0% {
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    100% {
      -webkit-transform: translate(-83.65%, -50%);
      -moz-transform: translate(-83.65%, -50%);
      -ms-transform: translate(-83.65%, -50%);
      -o-transform: translate(-83.65%, -50%);
      transform: translate(-83.65%, -50%);
    }
  }
}

span.stroke {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  z-index: 0;

  &:after {
    position: absolute;
    content: '';
    height: calc(100% + 0.48em);
    width: 0%;
    left: -0.1em;
    bottom: -0.25em;
    background: rgba(255, 255, 255, 0.4);
    z-index: -2;
    animation: strokeAnimation 0.5s cubic-bezier(0, 1, 0.8, 1) 1;
    animation-delay: 0.6s;
    animation-fill-mode: forwards;

    @include breakpoint(small down) {
      height: calc(100% + 0.2em);
      bottom: -0.1em;
    }
  }

  @keyframes strokeAnimation {
    0% {
      width: 20%;
    }
    100% {
      width: calc(100% + 0.2em);
    }
  }
}

section.contact {
  .section-content {
    div[class$='inner'] {
      padding-bottom: 0;

      img {
        max-width: 9em;
        margin-left: auto;
        margin-right: auto;
        @include margin-bottom(-0.375);

        @include breakpoint(small down) {
          bottom: -0.1em;
          max-width: 5em;
          @include margin-bottom(-0.2);
        }
      }
    }

    p.preamble {
      @include breakpoint(small down) {
        font-size: 100%;
        @include line-height(0.875);
      }
    }
  }
}

#video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}
