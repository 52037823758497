// Breakpoints
@mixin breakpoint($breakpoint) {
  // Small
  @if $breakpoint == (small) or $breakpoint == (small only) or $breakpoint == (small down) {
    @media print, screen
    and (max-width: map-get($breakpoints, medium)) {
      @content;
    }
  }

  // Medium
  @else if $breakpoint == (medium only) {
    @media print, screen
    and (min-width: map-get($breakpoints, medium))
    and (max-width: map-get($breakpoints, large)) {
      @content;
    }
  }

  @else if $breakpoint == (medium) or $breakpoint == (medium up) {
    @media print, screen
    and (min-width: map-get($breakpoints, medium)) {
      @content;
    }
  }

  @else if $breakpoint == (medium down) {
    @media print, screen
    and (max-width: (map-get($breakpoints, large) - 1px) ) {
      @content;
    }
  }

  // Large
  @else if $breakpoint == (large only) {
    @media print, screen
    and (min-width: map-get($breakpoints, large))
    and (max-width: map-get($breakpoints, xlarge)) {
      @content;
    }
  }

  @else if $breakpoint == (large) or $breakpoint == (large up) {
    @media print, screen
    and (min-width: map-get($breakpoints, large)) {
      @content;
    }
  }

  @else if $breakpoint == (large down) {
    @media print, screen
    and (max-width: (map-get($breakpoints, xlarge) - 1px) ) {
      @content;
    }
  }

  // Xlarge
  @else if $breakpoint == (xlarge only) {
    @media print, screen
    and (min-width: map-get($breakpoints, xlarge))
    and (max-width: map-get($breakpoints, xxlarge)) {
      @content;
    }
  }

  @else if $breakpoint == (xlarge) or $breakpoint == (xlarge up) {
    @media print, screen
    and (min-width: map-get($breakpoints, xlarge)) {
      @content;
    }
  }

  @else if $breakpoint == (xlarge down) {
    @media print, screen
    and (max-width: ( map-get($breakpoints, xlarge) - 1px) ) {
      @content;
    }
  }

  // Xxlarge
  @else if $breakpoint == (xxlarge) or $breakpoint == (xxlarge up) {
    @media print, screen
    and (min-width: map-get($breakpoints, xxlarge)) {
      @content;
    }
  }

  @else if $breakpoint == (xxlarge down) {
    @media print, screen
    and (max-width: ( map-get($breakpoints, xxlarge)) ) {
      @content;
    }
  }

  // False
  @else {
    @warn "`#{$breakpoint}` is not a valid key in `$breakpoints`.";
  }
}

// Typography
// Vertical rhythm mixins
@mixin line-height($number) { line-height: #{ $number * $line-height + 'rem'}; }

@mixin margin-top($number) { margin-top: #{ $number * $line-height + 'rem'}; }
@mixin margin-bottom($number) { margin-bottom: #{ $number * $line-height + 'rem'}; }
@mixin margin-left($number) { margin-left: #{ $number * $line-height + 'rem'}; }
@mixin margin-right($number) { margin-right: #{ $number * $line-height + 'rem'}; }

@mixin padding-top($number) { padding-top: #{ $number * $line-height + 'rem'}; }
@mixin padding-bottom($number) { padding-bottom: #{ $number * $line-height + 'rem'}; }
@mixin padding-left($number) { padding-left: #{ $number * $line-height + 'rem'}; }
@mixin padding-right($number) { padding-right: #{ $number * $line-height + 'rem'}; }

// Set line-heights and margins
@each $heading, $properties in $headings {
  #{$heading} {
    @include line-height(nth($properties, 1));
    @include margin-top(nth($properties, 2));
    @include margin-bottom(nth($properties, 3));
  }
}

// Reset button
@mixin button-reset {
  display: inline-flex;
  align-items: center;
  text-align: center;
  border: none;
  padding: 0;
  text-decoration: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

@mixin button-style {
  background-color: $pink;
  color: $purple;
  text-align: center;
  border-radius: 50px;
  padding: calc(#{$line-height * 0.625}rem - 3px) calc(#{$line-height}rem - 3px);
  width: 100%;
  max-width: 330px;
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;
  font-style: inherit;
  font-family: 'Platform LC Web';
  font-size: 120%;

    &:hover {
      background: $purple;
      color: $pink;
    }
}