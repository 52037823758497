.marker {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    border: 4px dotted #333;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
