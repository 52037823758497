.apply-form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: auto;
    color: $black;
    @include margin-top(2.3);
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(large up) {
        max-width: 900px;
    }

    .wrapper-inputs {
        display: block;
        @include breakpoint(large up) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .form-label {
            width: 100%;
            @include breakpoint(large up) {
                width: 48%;
            }
        }
    }

    label[for="message"] {
        margin-bottom: 10px;
        display: block;
        font-size: 18px;
    }

    textarea {
        width: 100%;
        appearance: none;
        border: none;
        border-radius: 5px;
        color: $black;
        background: $light-gray;
        padding: 12px;
        min-height: 200px;
        font-family: 'Apercu Pro';
        font-size: 85%;
        margin-bottom: 2.5rem;
        outline: none;

        &:focus-visible {
            border: 1px solid $purple;
        }

        @include breakpoint(medium up) {
            font-size: 100%;
        }
    }

    .form-label {
        display: flex;
        flex-direction: column;
        @include margin-bottom(2.5);
        position: relative;

        .required-field-text {
            font-size: 13px;
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            transition: all 0.2s ease-in;
            font-style: italic;
            color: $black;
            font-family: 'Apercu Pro';
            transition: 0.23s color ease;
        }
        &.noVal {
            .required-field-text {
                color: red;
            }
        }

        label {
            color: $black;
            font-family: 'Apercu Pro';
            @include margin-bottom(0.2);
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0, -32px);
            transition: all 0.13s ease;
            font-size: 100%;
            font-style: italic;
        }

        .file-upload {
            @include button-style;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                display: inline-flex;

                svg {
                    margin-left: 5px;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        input {
            padding: calc(#{$line-height * 0.625}rem - 8px) calc(#{$line-height}rem - 22px);
            border: 0;
            transition: all 0.2s ease-out;
            padding-left: 0;
            font-size: 1.2rem;
            padding-right: 0;
            width: 100%;
            background: $light-gray;
            border-radius: 10px;

            @include breakpoint(medium up) {
                padding: calc(#{$line-height * 0.625}rem - 8px) calc(#{$line-height}rem - 22px);

            }

            &:-webkit-autofill {
                background-color: white;
            }

            &[type="text"], &[type="tel"], &[type="email"] {
                font-family: 'Platform LC Web';
                letter-spacing: 2px;
                font-size: 120%;
                transition: outline 0.23s ease;

                &:hover, &:focus {
                    outline: 1px solid $purple;
                }
            }

            &[type="file"] {
                transition: none;

                &:focus {
                    border-bottom: none;
                    background: $white;
                }
            }

            &:focus, &:focus-visible {
                outline: none;
            }
        }
    }
    .file-selected {
        font-size: 13px;
        font-style: italic;
    }

    .checkbox-wrapper {
        @include margin-bottom(2);
        flex-direction: row;
        align-items: center;

        .required-field-text {
            width: 100%;
        }

        input {
            margin: 0;
            display: block;
            height: 28px;
            width: 28px;
            border: 2px solid $purple;
            appearance: none;
            margin-right: 8px;
            border-radius: 5px;
            position: relative;
            cursor: pointer;
            min-width: 28px;

            &:checked {
                background-color: $purple;

                &:after {
                    content: "✓";
                    display: block;
                    position: absolute;
                    color: $pink;
                    font-size: 20px;
                    font-weight: bold;
                    left: 4px;
                    bottom: 0px;
                }
            }
        }
    }

    .hide {
        display: none !important;
    }
    .thank_you, .form_error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $black;
        min-height: 200px;
        margin-bottom: 200px;
        padding: calc(#{$line-height * 0.625}rem + 10px) calc(#{$line-height}rem + 10px);

        @include breakpoint(medium up) {
            padding: calc(#{$line-height * 0.625}rem + 10px) calc(#{$line-height}rem + 10px);
        }
    }
    .thank_you {
        background-color:$purple;
        color: $pink;
    }
    .form_error {
        background: $red;
        color: white;
    }

    button[type="submit"] {
        border-bottom: none;
        @include button-style;
        display: flex;
        justify-content: center;
        @include margin-bottom(4);
    }

    .hasVal {
        .required-field-text {
            color: $black !important;
        }
    }
}

.form-intro {
    @include margin-bottom(2.3);
    max-width: 500px;
    margin: auto;

    @include breakpoint(large up) {
        max-width: 900px;
    }

    &.hide {
        display: none !important;
    }
}