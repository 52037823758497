
$white: #FFFFFF;
$black: #333333;
$gray: #CCCCCC;
$purple:#3d3767;
$red: #F16360;
$pink: #FBC3BE;
$eggshell: #FFF4ED;
$light-gray: #F0EFEF;

$breakpoints: (
  small: 0,
  medium: 768px,
  large: 1024px,
  xlarge: 1366px,
  xxlarge: 1680px
);

// Typography - Vertical Rythm Mixin
// Gets used as % (112.5 = 18 px, 125 = 20 px, 137.5 = 22 px, 150 = 24 px)
$base-font-size: 125;
$base-font-size-small: 120;
$line-height: 1.6;

// Headings - Vertical Rythm Mixin
// Headings parameters [ h1: line-height: 2 × 26px, margin-top: 3 × 26px, margin-bottom: 1 × 26px ]
$headings: (
  h1: (2, 2.5, 1),
  h2: (1.25, 2.75, 1),
  h3: (1.25, 1.5, 0.5),
  h4: (1.125, 1.75, 0.5),
  h5: (1, 1.5, 0.5)
) !default;

// Helpers
$global-gutter: #{$line-height * 1.5}rem;
$global-gutter-small: $line-height+rem;

$global-border-radius: 2em;