@font-face {
  font-family: 'Platform LC Web';
  src: url('./Platform-Bold-Cy-Web.woff2') format('woff2'),
       url('./Platform-Bold-Cy-Web.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

.Platform-Bold-Cy-Web {
  font-family: 'Platform LC Web';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}
