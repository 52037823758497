body {
  color: $black;
}

.App {
  padding-bottom: 290px;
  position: relative;
}

p {
  line-height: 1.4;
}

ul {
  line-height: 1.4;
}

::selection {
  background-color: rgba(darken($purple, 10%), 0.8);
  color: $white;
}

a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

a.cta,
button.cta {
  position: relative;
  @include button-reset;
  @include line-height(0.75);
  color: $white;
  background-color: $purple;
  font-size: 100%;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
  padding: calc(#{$line-height * 0.625}rem - 2px) calc(#{$line-height}rem - 2px);
  outline: none;
  border: 1px solid $purple;
  border-radius: $global-border-radius;
  bottom: 0;
  transition: background-color 150ms ease-in-out, bottom 150ms ease-in-out;

  @include breakpoint(large up) {
    min-width: 10rem;
  }

  &:hover {
    background-color: lighten($purple, 10%);
  }

  &:active {
    bottom: -2px;
  }
}

// Cookie consent
.CookieConsent {
  @include padding-top(0.5);
  @include padding-bottom(0.5);

  @include breakpoint(small down) {
    @include padding-bottom(0.5);
  }

  &-inner {
    @extend .gutter;
    @include breakpoint(large up) {
      padding-right: 0 !important;
    }
  }

  &-button {
    @extend .gutter;
    margin-bottom: 0;
    @include breakpoint(small down) {
      @include margin-top(0.5);
      @include margin-bottom(0.5);
    }
  }

  .cta {
    padding: #{$line-height * 0.375}rem #{$line-height * 0.5}rem !important;
    border-radius: 2em !important;
    min-width: 4rem;

    @include breakpoint(small down) {
      padding: #{$line-height * 0.25}rem #{$line-height * 0.375}rem !important;
    }
  }
}
