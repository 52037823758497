// Remove default margin
html, body {
  margin: 0;
  width: 100%;
}

// Set core body defaults
body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

#root {
  margin-bottom: 0;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

// Remove default margin
h1, h2, h3, h4,
p,
ul[class], ol[class], li,
figure, figcaption,
blockquote,
dl, dd
{
  margin-left: 0;
  margin-right: 0;
}

hr {
  border-top: none;
  border-bottom: 1px solid $gray;
}

button {
  @include button-reset;
}

.gutter {
  padding-left: $global-gutter;
  padding-right: $global-gutter;

  @include breakpoint(small down) {
    padding-left: $global-gutter-small;
    padding-right: $global-gutter-small;
  }
}

.text-center {
  text-align: center;
}
