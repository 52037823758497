.nav {
  color: #ffffff;
  font-family: 'Platform LC Web';
  font-weight: 400;
  font-size: 1.5rem;
  height: 60px;
  left: 0px;
  padding-top: 10px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 100;
}

.nav-mobile-wrapper {
  display: none;
}
.nav-mobile-links {
  display: none;
}

@media (max-width: 990px) {
  .nav {
    display: none;
  }
  .nav-mobile-wrapper {
    display: flex;
  }
  .nav-mobile {
    display: flex;
    color: white;
    font-family: 'Platform LC Web';
    font-weight: 400;
    font-size: 1.5rem;
    padding: 0 15px;
    left: 0px;
    position: absolute;
    top: 10px;
    width: 100%;
    z-index: 200;
  }
  .nav-mobile-links {
    background-color: rgba(31, 29, 29, 0.95);
    transition: width 2s;
    display: flex;
    flex-direction: column;
    width: 50%;
    position: absolute;
    right: 0px;
    height: 100vh;
    color: white;
    font-family: 'Platform LC Web';
    font-weight: 400;
    font-size: 1.5rem;
    padding: 10px 0px;
    z-index: 100;
  }
  .nav-mobile .nav-link {
    flex-grow: 8;
    padding: 0 1em 0 0em;
  }
  .link-section-mobile {
    font-size: 1.5rem;
    padding-top: 36px;
    width: 100%;
  }
}

.nav:hover {
  // box-shadow: 0px -30px 50px 20px black
}

.nav-logo {
  display: inline-block;
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
  display: inline-block;
  padding: 0 1em 0 1em;
  text-decoration: none;
}

.nav-link-mobile {
  cursor: pointer;
  display: inline-block;
  padding: 15px 1em 0 0em;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
}
// .nav-link::after {
//   content: '';
//   display: block;
//   width: 0;
//   height: 1px;
//   background: #fff;
//   transition: width 0.5s;
// }
// .nav-link:hover::after {
//   width: 100%;
//   //transition: width .3s;
// }
@media only screen and (max-width: 1000px) {
  //   .nav-link {
  //     // font-size: 1.5rem;
  //     // padding: 0 0.7rem 0 0.7rem;
  //   }
}
@media only screen and (max-width: 720px) {
  .nav {
    font-size: 1.5rem;
    padding: 0 0.5rem 0 0.5rem;
  }
  .nav-link::after {
    display: none;
  }
  // //   .nav-link {
  // //     font-size: 0.8rem;
  // //     padding: 0 0.5rem 0 0.5rem;
  //   }
}

.link-section {
  font-size: 1.5rem;
  float: right;
}
